import React, { Suspense } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { isSSR } from "../utils/constants"
import Layout from "../components/layout"
import SEO from "../components/Seo"
import Spinner from "../components/Spinner"
import Resource from "../config/language.json"
import CardResource from "../components/ProductDetail/language.json"

const ProductDetail = React.lazy(() => import("../components/ProductDetail"))
const component = Resource[process.env.GATSBY_SITE_VERSION]
const adicionalComponent = CardResource[process.env.GATSBY_SITE_VERSION]

export default () => {
  const data = useStaticQuery(graphql`
    query {
      nationalApprovalFairMx: file(
        relativePath: {
          eq: "mx/landings/feria-de-aprobacion-nacional-iban-online-mexico.jpg"
        }
      ) {
        ...customFragmentImageFluid
      }
    }
  `)

  return (
    <>
      {!isSSR && (
        <Suspense fallback={<Spinner />}>
          <SEO
            title={component.nationalApprovalFair.SEO.title}
            keywords={component.nationalApprovalFair.SEO.keywords}
            description={component.nationalApprovalFair.SEO.description}
          />
          <Layout>
            <ProductDetail
              pageData={{
                credit: component.nationalApprovalFair.pageData.credit,
                alt: component.nationalApprovalFair.pageData.alt,
                minAmount: component.nationalApprovalFair.minAmount,
                minLoanDurationInMonths:
                  component.nationalApprovalFair.minLoanDurationInMonths,
                titleProduct:
                  component.nationalApprovalFair.pageData.textProduct,
                textProduct:
                  component.nationalApprovalFair.pageData.textProductBr,
                href: component.nationalApprovalFair.pageData.href,
                textBenefits:
                  component.nationalApprovalFair.pageData.textBenefits,
                secondBenefits:
                  component.nationalApprovalFair.pageData.secondBenefits,
                secondTextBenefits:
                  component.nationalApprovalFair.pageData.secondTextBenefits,
                image: data.nationalApprovalFairMx.childImageSharp.fluid,
                cardDataBottom: adicionalComponent.cardNationalApprovalFair,
                form: component.nationalApprovalFair.form.inputs,
                cardDataHeader:
                  component.nationalApprovalFair.pageData.cardDataHeader,
                fair: component.nationalApprovalFair.pageData.fair,
                fontSize: adicionalComponent.fontSize,
              }}
            />
          </Layout>
        </Suspense>
      )}
    </>
  )
}
